.title {
  font-size: 3rem;
  font-family: "Assistant";
  text-align: center;
  width: 90%;
  margin: 2% auto;
  direction: rtl;
  color: rgb(66, 43, 11);

}

.description {
  font-size: 1.4rem;
  font-family: "AssistantR";
  text-align: center;
  direction: rtl;
  margin: 2% auto;
}

.bold {
  display: inline;
  font-family: "Assistant";
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/* Responsive styles */

@media (max-width: 350px) {
  .title {
    font-size: 1.7rem;
    width: 95%;
  }
  .description {
    font-size: 1.1rem;
    width:95%;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .title {
    font-size: 1.8rem;
    width: 90%;
  }
  .description {
    font-size: 1.1rem;
    width: 95%;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .title {
    font-size: 2rem;
    width: 85%;
  }
  .description {
    font-size: 1.2rem;
    width: 90%;
  }
  .row{
    width:80%;
    margin:0 auto;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .title {
    font-size: 2.2rem;
    width: 80%;
  }
  .description {
    font-size: 1.25rem;
    width: 80%;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .title {
    font-size: 2.6rem;
    width: 75%;
  }
  .description {
    font-size: 1.3rem;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .title {
    font-size: 2.7rem;
    width: 70%;
  }
  .description {
    font-size: 1.35rem;
  }
}

@media (min-width: 850px) and (max-width: 950px) {
  .title {
    font-size: 2.8rem;
    width: 65%;
  }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .title {
    font-size: 2.9rem;
    width: 60%;
  }
}

@media (min-width: 1050px) and (max-width: 1150px) {
  .title {
    font-size: 3rem;
    width: 55%;
  }
}

@media (min-width: 1150px) and (max-width: 1250px) {
  .title {
    width: 50%;
  }
}
/* ... (previous styles remain the same) ... */

.title {
  font-size: 3rem;
  font-family: "Assistant";
  text-align: center;
  width: 50%;
  margin: 3% auto;
  direction: rtl;

  color: rgb(66, 43, 11);

}

/* Responsive styles */
@media (max-width: 349px) {
 
  .title {
      font-size: 1.8rem;
      width: 90%;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
 
  .title {
      font-size: 2rem;
      width: 85%;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
 
  .title {
      font-size: 2.2rem;
      width: 80%;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
 
  .title {
      font-size: 2.2rem;
      width: 75%;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
 
  .title {
      font-size: 2.4rem;
      width: 70%;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
 
  .title {
      font-size: 2.6rem;
      width: 65%;
  }
}

@media (min-width: 850px) and (max-width: 950px) {
 
  .title {
      font-size: 2.8rem;
      width: 60%;
  }
}

@media (min-width: 950px) and (max-width: 1050px) {
 
  .title {
      font-size: 2.9rem;
      width: 55%;
  }
}

@media (min-width: 1050px) and (max-width: 1150px) {
 
  .title {
      font-size: 3rem;
      width: 52%;
  }
}

@media (min-width: 1150px) and (max-width: 1250px) {
 
  .title {
      font-size: 3rem;
      width: 50%;
  }
}
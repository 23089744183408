.container {
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;

}

.header {
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    margin: 0 auto 2%;
    padding-top: 2%;
    direction: rtl;
    color: rgb(66, 43, 11);
    text-shadow: 2px 2px 4px rgba(93, 64, 55, 0.3);
}

.imageContainer {
    flex: 1;
    padding-right: 20px;
}

.image {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.content {
    flex: 2;
    text-align: right;
    direction: rtl;
}

.title {
    font-size: 2.5em;
    color: #4e260b;
    margin-bottom: 10px;
    font-family: "Assistant";
}

.subtitle {
    font-size: 1.2em;
    color: #66341d;
    font-family: "Assistant";
    margin-bottom: 15px;
    direction: rtl;
}

.description {
    font-size: 1em;
    line-height: 1.6;
    font-family: "AssistantR";
    color: #6B4423;
}

@media only screen and (max-width: 1050px) {
    .container {
        width: 90%;
        max-width: none;
    }
}

@media only screen and (max-width: 350px) {
    .container {
        flex-direction: column;
        padding: 10px;
    }
    
    .header {
        font-size: 2.2rem;
    }
    
    .imageContainer {
        padding-right: 0;
        padding-bottom: 10px;
    }
    
    .content {
        text-align: center;
    }
    
    .title {
        font-size: 2rem;
    }
    
    .subtitle {
        font-size: 1.2rem;
    }
    
    .description {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .container {
        flex-direction: column;
        padding: 15px;
    }
    
    .header {
        font-size: 2.4rem;
    }
    
    .imageContainer {
        padding-right: 0;
        padding-bottom: 15px;
    }
    .image{
        width:70%;
       display: flex;
       justify-content: center;
       margin:0 auto;
    }
    .content {
        text-align: center;
    }
    
    .title {
        font-size: 2.2rem;
    }
    
    .subtitle {
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .container {
        flex-direction: column;
    }
    
    .header {
        font-size: 3rem;
    }
    .image{
        width:60%;
       display: flex;
       justify-content: center;
       margin:0 auto;
    }
    .imageContainer {
        padding-right: 0;
        padding-bottom: 20px;
    }
    
    .content {
        text-align: right;
    }
    
    .title {
        font-size: 2.4rem;
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .header {
        font-size: 3.1rem;
    }
    
    .title {
        font-size: 2.3em;
    }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .header {
        font-size: 3.2rem;
    }
    
    .title {
        font-size: 2.4em;
    }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .header {
        font-size: 3.3rem;
    }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .header {
        font-size: 3.4rem;
    }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
    .header {
        font-size: 3.5rem;
    }
}
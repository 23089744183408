/* Base styles */
.container {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 800px;
  margin: 20px auto;
}

.iconContainer {
  flex: 4;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.icon {
  width: 70%; /* Change from 50% to 100% */

  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  flex: 0 0 75%;
  padding: 20px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  order: 1;
}

.title {
  font-size: 24px;
  color: #4A3728;
  margin-bottom: 10px;
  direction: rtl;
}

.description {
  font-size: 16px;
  direction: rtl;
  color: #6B4F35;
}

/* Responsive styles */
@media (max-width: 350px) {
  .container { flex-direction: column; max-width: 90%; }
  .iconContainer, .contentContainer { flex: 0 0 100%; order: 0; }
  .icon{
    width:55%;
  }
  .iconContainer { padding: 15px; } /* Add padding for smaller screens */
  .contentContainer { padding: 15px; }
  .title { font-size: 18px; }
  .description { font-size: 14px; }
}

@media (min-width: 350px) and (max-width: 450px) {
  .container { flex-direction: column; max-width: 80%; }
  .iconContainer, .contentContainer { flex: 0 0 100%; order: 0; }
  .iconContainer { padding: 15px; } /* Add padding for smaller screens */
  .contentContainer { padding: 15px; }
  .icon{
    width:50%;
  }
  .title { font-size: 20px; }
  .description { font-size: 14px; }
}

@media (min-width: 450px) and (max-width: 550px) {
  .container { max-width: 95%; }

  .title { font-size: 21px; }
  .description { font-size: 14px; }
  .icon{
    width:85%;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .container { max-width: 95%; }

  .title { font-size: 22px; }
  .description { font-size: 15px; }
  .icon{
    width:85%;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .container { max-width: 90%; }
  .icon{
    width:85%;
  }
  .title { font-size: 23px; }
  .description { font-size: 15px; }
}

@media (min-width: 750px) and (max-width: 850px) {
  .container { max-width: 85%; }
  .title { font-size: 23px; }
}

@media (min-width: 850px) and (max-width: 950px) {
  .container { max-width: 80%; }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .container { max-width: 85%; }
}

@media (min-width: 1050px) and (max-width: 1150px) {
  .container { max-width: 90%; }
}

@media (min-width: 1150px) and (max-width: 1250px) {
  .container { max-width: 95%; }
}
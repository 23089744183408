.box{
    width:400px;
    height:auto;
    display: flex;
    flex-direction: column;
    margin: 2% auto;
}
.description{
    font-size:1.2rem;
    font-family: "AssistantR";
    text-align: center;
    margin:2% auto;
    direction:rtl;
    width:90%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: rgb(64, 45, 21);
  }

  .icon{
    display: flex;
    justify-content: center;
    width:30%;
    margin:0 auto;
  }
  @media only screen and (max-width: 350px){
    .box{
        width:300px;
        height:auto;
        display: flex;
        flex-direction: column;
        margin:3% auto;
    }
    .description{
        font-size:1.05rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        width:90%;
        text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
        color: rgb(64, 45, 21);
      }
  
      .icon{
        display: flex;
        justify-content: center;
        width:30%;
        margin:2% auto;
      }
  }
  @media only screen and (min-width: 350px) and (max-width: 450px){
    .box{
        width:180px;
        height:auto;
        display: flex;
        flex-direction: column;
        margin:3% auto;
    }
    .description{
        font-size:1.15rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        width:90%;
        text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
        color: rgb(64, 45, 21);
      }

      .icon{
        display: flex;
        justify-content: center;
        width:30%;
        margin:2% auto;
      }
  }
  @media only screen and (min-width: 450px) and (max-width: 550px){
    .box{
        width:190px;
        height:auto;
        display: flex;
        flex-direction: column;
        margin:3% auto;
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        width:95%;
        text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
        color: rgb(64, 45, 21);
      }
    
      .icon{
        display: flex;
        justify-content: center;
        width:25%;
        margin:2% auto;
      }
  }
  @media only screen and (min-width: 550px) and (max-width: 650px){
    .box{
        width:250px;
        height:auto;
        display: flex;
        flex-direction: column;
        margin:3% auto;
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        width:95%;
        text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
        color: rgb(64, 45, 21);
      }
    
      .icon{
        display: flex;
        justify-content: center;
        width:25%;
        margin:2% auto;
      }
  }
  @media only screen and (min-width: 650px) and (max-width: 750px){
    .box{
        width:280px;
        height:auto;
        display: flex;
        flex-direction: column;
        margin:3% auto;
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        width:95%;
        text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
        color: rgb(64, 45, 21);
      }
    
      .icon{
        display: flex;
        justify-content: center;
        width:25%;
        margin:2% auto;
      }
  }
  @media only screen and (min-width: 750px) and (max-width: 1050px){
    .box{
        width:300px;
        height:auto;
        display: flex;
        flex-direction: column;
        margin:3% auto;
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        width:95%;
        text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
        color: rgb(64, 45, 21);
      }
    
      .icon{
        display: flex;
        justify-content: center;
        width:25%;
        margin:2% auto;
      }
  }